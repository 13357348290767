<script>
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      students: [],
      classes: [],
      searchQuery: '',
      student: {
        nom: "",
        prenom: "",
        sexe: null,
        domicile: "",
        date_de_naissance: "",
        lieu_de_naissance: "",
        numero_de_telephone: "",
        numero_de_telephone_tuteur: "",
        classe: "",
      },
      data: {
        role: "etudiant",
      },
      page: 1,
      perPage: 5,
      pages: [],
      submited: false,
      spanView: false,

      title: "Nouvel Elève",
      items: [
        {
          text: "Elèves",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      nom: {
        required: helpers.withMessage("Le champs nom est requis", required),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
      },
      prenom: {
        required: helpers.withMessage("Le champs prenom est requis", required),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
      },
      sexe: {
        required: helpers.withMessage("Le champs genre est requis", required),
      },
      date_de_naissance: {
        required: helpers.withMessage(
          "Le champs date de naissance est requis",
          required
        ),
      },
      lieu_de_naissance: {
        required: helpers.withMessage(
          "Le champs Lieu de naissance est requis",
          required
        ),
      },
      domicile: {
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("30 characteres maximum", maxLength(30)),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    flatPickr,
    VueTelInput,
  },
  mounted() {
    this.getAllStudents();
    this.getAllFaculties();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.students);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.nom.toLowerCase().includes(search) ||
            data.prenom.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    initCreation() {
      this.submited = true;
      this.createStudent();
    },
    initUpdate() {
      this.submited = true;
      this.updateStudent();
    },
    createStudent() {
      this.v$.data.$touch();
      const self = this;
      this.spanView = true;
      this.data.annee_scolaire = localStorage.getItem("selectedYear");
      this.$store
        .dispatch("postRequest", { route: "/api/user", data: this.data })
        .then(
          function (response) {
            self.v$.data.$reset();
            self.spanView = false;
            self.data = {
              role: "etudiant",
            };
            Swal.fire(response.data.message, "Etudiant enrégistré!", "success");
            self.getAllStudents();
          },
          function (error) {
            self.spanView = false;
            if (error !== null) {
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },
    updateStudent() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch("putRequest", {
          route: `api/user/${this.student.id}`,
          data: this.student,
        })
        .then(
          function (response) {
            self.spanView = false;
            self.getAllStudents();
            Swal.fire(
              response.data.message,
              "Modification éffectuer avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },
    async deleteStudent(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cet élève ? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value == 1) {
          this.$store
            .dispatch("deleteRequest", {
              route: `api/user/${id}`,
              data: this.data,
            })
            .then(
              function (response) {
                self.getAllStudents();
                console.log(response);
                Swal.fire({
                  title: "Supprimer",
                  text: "élève supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },
    getStudent(data) {
      this.student = {
        id: data.id,
        nom: data.nom,
        prenom: data.prenom,
        sexe: data.sexe,
        domicile: data.domicile,
        date_de_naissance: data.date_de_naissance,
        lieu_de_naissance: data.lieu_de_naissance,
        numero_de_telephone: data.numero_de_telephone?.toString(),
        numero_de_telephone_tuteur: data.numero_de_telephone_tuteur?.toString(),
        matricule: data.matricule,
        email: data.email,
      };
    },
    getAllStudents() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/etudiant ",
          data: this.data,
        })
        .then(
          function (response) {
            self.students = response.data.searchedUsers;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },
    getAllFaculties() {
      const self = this;
      this.$store
        .dispatch("getRequest", { route: "api/departments", data: this.data })
        .then(
          function (response) {
            response.data.filieres.forEach((element) => {
              const group = element.group ? element.group?.toUpperCase() : " ";
              const name = element.full_classe?.name.toUpperCase();
              self.classes.push({
                value: element.id,
                label: name + " " + group,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.students.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<style scoped>
#numero_de_telephone {
  height: 38px;
}
#numero_de_telephone_tuteur {
  height: 38px;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" v-model="searchQuery" placeholder="Search..." />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Elèves
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="currency_name" scope="col">
                    Matricule
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Nom
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Prenom
                  </th>
                  <!-- <th class="sort" data-sort="pairs" scope="col">Email</th> -->
                  <th class="sort" data-sort="pairs" scope="col">Téléphone</th>
                  <th class="sort" data-sort="high" scope="col">Sexe</th>
                  <th class="sort" data-sort="high" scope="col">Satut</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td class="id">{{ data.matricule }}</td>
                  <td>{{ data.nom }}</td>
                  <td>{{ data.prenom }}</td>
                  <!-- <td class="pairs">{{ data.email ? data.email : 'Aucun'}}</td> -->
                  <td class="pairs">
                    {{
                      data.numero_de_telephone
                        ? data.numero_de_telephone
                        : "Aucun"
                    }}
                  </td>
                  <td>{{ data.sexe.toUpperCase() }}</td>
                  <td>
                    <span
                      class="badge font-size-11 m-1"
                      :class="{
                        'bg-success': `${data.status}` === 'active',
                        'bg-warning': `${data.status}` === 'waiting',
                        'bg-danger': `${data.status}` === 'inactive',
                      }"
                      >{{ data.status }}</span
                    >
                  </td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalgrid"
                        @click="getStudent(data)"
                        class="ml-3 link-success fs-15"
                        ><i class="ri-eye-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="link-success fs-15"
                        data-bs-toggle="modal"
                        data-bs-target=".bs-update-modal"
                        @click="getStudent(data)"
                      >
                        <i class="ri-edit-2-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="link-danger fs-15"
                        @click="deleteStudent(data.id)"
                      >
                        <i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div
            class="modal fade"
            id="exampleModalgrid"
            tabindex="-1"
            aria-labelledby="exampleModalgridLabel"
            aria-modal="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body card">
                  <div class="card-body">
                    <div class="text-center mb-2">
                      <div
                        class="profile-user position-relative d-inline-block mx-auto mb-4"
                      >
                        <img
                          src="@/assets/images/users/avatar-1.jpg"
                          class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile-image"
                        />
                      </div>
                      <h5 class="fs-16 mb-1">
                        {{ this.student.prenom }} {{ this.student.nom }}
                      </h5>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <th class="ps-0" scope="row">Matricule :</th>
                            <td class="text-muted">
                              {{ this.student.matricule }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Téléphone :</th>
                            <td class="text-muted">
                              {{ this.student.numero_de_telephone }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">E-mail :</th>
                            <td class="text-muted">
                              {{ this.student.email }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Sexe :</th>
                            <td class="text-muted">
                              {{ this.student.sexe }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">Adresse :</th>
                            <td class="text-muted">
                              {{ this.student.domicile }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">
                              Date de naissance :
                            </th>
                            <td class="text-muted">
                              {{ this.student.date_de_naissance }}
                            </td>
                          </tr>

                          <tr>
                            <th class="ps-0" scope="row">
                              Lieu de naissance :
                            </th>
                            <td class="text-muted">
                              {{ this.student.lieu_de_naissance }}
                            </td>
                          </tr>

                          <tr v-if="student.status === 'waiting'">
                            <th class="ps-0" scope="row">
                              Lien de confirmation:
                            </th>
                            <td>
                              <a
                                :href="
                                  'http://localhost:8080/account-confirm/' +
                                  student.code
                                "
                                class="text-muted"
                              >
                                LIEN
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Ajouter élèves
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstname" class="form-label">Nom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="firstname"
                          v-model="data.nom"
                          :class="{
                            'is-invalid':
                              (v$.data.nom.$error && data.nom) ||
                              (v$.data.nom.$error && submited),
                          }"
                          placeholder="Nom"
                        />
                        <div
                          v-for="(item, index) in v$.data.nom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.nom) ||
                              (v$.data.nom.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastname" class="form-label">Prénom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="lastname"
                          v-model="data.prenom"
                          :class="{
                            'is-invalid':
                              (v$.data.prenom.$error && data.prenom) ||
                              (v$.data.prenom.$error && submited),
                          }"
                          placeholder="Prénom"
                        />
                        <div
                          v-for="(item, index) in v$.data.prenom.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.prenom) ||
                              (v$.data.prenom.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="sexe" class="form-label">Genre</label>
                        <Multiselect
                          class="form-control"
                          v-model="data.sexe"
                          :class="{
                            'is-invalid':
                              (v$.data.sexe.$error && data.sexe) ||
                              (v$.data.sexe.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          placeholder="Sexe"
                          :options="[
                            { value: 'masculin', label: 'Masculin' },
                            { value: 'feminin', label: 'Feminin' },
                          ]"
                        />
                        <div
                          v-for="(item, index) in v$.data.sexe.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.sexe) ||
                              (v$.data.sexe.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Address</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="data.domicile"
                          :class="{
                            'is-invalid':
                              (v$.data.domicile.$error && data.domicile) ||
                              (v$.data.domicile.$error && submited),
                          }"
                          placeholder="Adresse"
                        />
                        <div
                          v-for="(item, index) in v$.data.domicile.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.domicile) ||
                              (v$.data.domicile.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="date_de_naissance" class="form-label"
                          >Date de naissance
                        </label>

                        <flat-pickr
                          class="form-control"
                          v-model="data.date_de_naissance"
                          placeholder="Date de naissance"
                          :class="{
                            'is-invalid':
                              (v$.data.date_de_naissance.$error &&
                                data.date_de_naissance) ||
                              (v$.data.date_de_naissance.$error && submited),
                          }"
                        />

                        <div
                          v-for="(item, index) in v$.data.date_de_naissance
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.date_de_naissance) ||
                              (v$.data.date_de_naissance.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Lieux de naissance</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="data.lieu_de_naissance"
                          :class="{
                            'is-invalid':
                              (v$.data.lieu_de_naissance.$error &&
                                data.lieu_de_naissance) ||
                              (v$.data.lieu_de_naissance.$error && submited),
                          }"
                          placeholder="Lieux de naissance"
                        />
                        <div
                          v-for="(item, index) in v$.data.lieu_de_naissance
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.lieu_de_naissance) ||
                              (v$.data.lieu_de_naissance.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="numero_de_telephone" class="form-label"
                          >Numéro de téléphone de l'élèves</label
                        >
                        <vue-tel-input
                          class="form-control"
                          v-model="data.numero_de_telephone"
                          id="numero_de_telephone"
                          mode="international"
                          placeholder="Numero de téléphone de l'étudiant"
                        ></vue-tel-input>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label
                          for="numero_de_telephone_tuteur"
                          class="form-label"
                          >Numéro de téléphone du tuteur</label
                        >
                        <vue-tel-input
                          class="form-control"
                          v-model="data.numero_de_telephone_tuteur"
                          id="numero_de_telephone_tuteur"
                          mode="international"
                          placeholder="Numero de téléphone du tuteur"
                        ></vue-tel-input>
                      </div>
                    </div>
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          v-model="data.email"
                          placeholder="Email de l'étudiant"
                        />
                      </div>
                    </div> -->
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="classe" class="form-label">Classe</label>
                        <Multiselect
                          class="form-control"
                          v-model="data.classe"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          :options="classes"
                          placeholder="Classe"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-update-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Modifier élèves
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstname" class="form-label">Nom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="firstname"
                          v-model="student.nom"
                          placeholder="Nom"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastname" class="form-label">Prénom</label>
                        <input
                          type="text"
                          class="form-control"
                          id="lastname"
                          v-model="student.prenom"
                          placeholder="Prénom"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="sexe" class="form-label">Genre</label>
                        <Multiselect
                          class="form-control"
                          v-model="student.sexe"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          placeholder="Sexe"
                          :options="[
                            { value: 'masculin', label: 'Masculin' },
                            { value: 'feminin', label: 'Feminin' },
                          ]"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Address</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="student.domicile"
                          placeholder="Adresse"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="date_de_naissance" class="form-label"
                          >Date de naissance
                        </label>

                        <flat-pickr
                          class="form-control"
                          v-model="student.date_de_naissance"
                          placeholder="Date de naissance"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Lieux de naissance</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="designationInput"
                          v-model="student.lieu_de_naissance"
                          placeholder="Lieux de naissance"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="numero_de_telephone" class="form-label"
                          >Numéro de téléphone de l'élèves</label
                        >
                        <vue-tel-input
                          class="form-control"
                          v-model="student.numero_de_telephone"
                          id="numero_de_telephone"
                          mode="international"
                          placeholder="Numero de téléphone de l'étudiant"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label
                          for="numero_de_telephone_tuteur"
                          class="form-label"
                          >Numéro de téléphone du tuteur</label
                        >
                        <vue-tel-input
                          class="form-control"
                          v-model="student.numero_de_telephone_tuteur"
                          id="numero_de_telephone_tuteur"
                          mode="international"
                          placeholder="Numero de téléphone du tuteur"
                        />
                      </div>
                    </div>

                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Modifier
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#number {
  height: 39px;
}
</style>
